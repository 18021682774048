import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { FaFile } from 'react-icons/fa'

const DocumentCard = ({ cardTitle, internalLink, internalLinkPath, buttonLink, buttonName, buttonComingSoon }) => {
  return (
    <div className='flex flex-col lg:flex-row items-center border rounded-md p-4 bg-gradient-to-r from-gray-900 to-red-700 hover:from-red-900'>
      <div className='w-full lg:w-2/3 flex items-center mr-0 xl:mr-2'>
        <FaFile className='text-2xl md:text-3xl text-red-100 mr-3' />
        <h2 className='text-lg md:text-xl font-bold text-red-100'>{cardTitle ? cardTitle : "Document Name"}</h2>
      </div>
      <div className='w-full lg:w-1/3 lg:flex lg:justify-end'>
        {internalLink
          ?
          buttonComingSoon
            ?
            <button className="w-full flex items-center justify-center px-4 py-2 md:px-5 md:py-3 mt-6 lg:mt-0 border border-transparent font-bold rounded-md text-sm text-white tracking-widest bg-gray-900 hover:shadow-xl uppercase cursor-text">
              Coming Soon
            </button>
            :
            <Link to={internalLinkPath ? internalLinkPath : "/"}>
              <button className="w-full flex items-center justify-center px-4 py-2 md:px-5 md:py-3 mt-6 lg:mt-0 border border-transparent font-bold rounded-md text-sm text-red-900 tracking-widest bg-white hover:shadow-xl uppercase">
                {buttonName ? buttonName : "Download"}
              </button>
            </Link>
          :
          <a href={buttonLink ? buttonLink : 'https://www.831b.com'} target="_blank" rel="noreferrer noopener">
            <button className="w-full flex items-center justify-center px-4 py-2 md:px-5 md:py-3 mt-6 lg:mt-0 border border-transparent font-bold rounded-md text-sm text-red-900 tracking-widest bg-white hover:shadow-xl uppercase">
              {buttonName ? buttonName : "Download"}
            </button>
          </a>
        }
      </div>
    </div>
  )
}

DocumentCard.propTypes = {
  cardTitle: PropTypes.string,
  buttonLink: PropTypes.string,
  buttonName: PropTypes.string,
  internalLink: PropTypes.bool,
  buttonComingSoon: PropTypes.bool,
  internalLinkPath: PropTypes.string,
}

export default DocumentCard