import React from 'react'
import PropTypes from 'prop-types'

const DocumentCardsContainer = ({ children, containerTitle }) => {
  return (
    <div className='w-full bg-white rounded-md shadow-md mt-16'>
      <div className='p-4 xl:p-8'>
        <h2 className='text-2xl text-gray-900 font-bold'>{containerTitle ? containerTitle : null}</h2>
      </div>
      <div className='grid grid-cols-1 xl:grid-cols-2 gap-8 p-4 xl:pb-8 xl:px-8'>
        {children}
      </div>
    </div>
  )
}

DocumentCardsContainer.propTypes = {
  children: PropTypes.node,
  containerTitle: PropTypes.string,
}

export default DocumentCardsContainer